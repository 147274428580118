/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.

    function tileRecipeSlick(el){
        el.not('.slick-initialized').slick({
          slidesToShow: 3,
          slidesToScroll: 3,
          prevArrow:el.parent().find('.slick-prev'),
          nextArrow:el.parent().find('.slick-next'),
          dots: false,
          fade: false,
          infinite: false,
          responsive: [
            {
              breakpoint: 992,
              settings: 'unslick'
            }
          ]
        });
    }

    function initRecipeCarrousel(el){
        el.find('.tile-article__image').on('mouseenter', function(){
            if($(this).closest(".fc-recipe-carrousel").length){
                $(this).closest(".fc-recipe-carrousel").addClass('show-slider');
                // Les sliders étant cachés, on doit set la position au hover pour set height/width des slides
                $(this).closest(".fc-recipe-carrousel").find('.other-recipes-slider__slides').get(0).slick.setPosition();
            }
        });

        el.on('mouseleave', function(){
            $(this).removeClass('show-slider');
        });

        $(window).on('resize', function(e) {
            var resizeTimer;
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function() {
                $(".other-recipes-slider__slides").each(function(){
                tileRecipeSlick($(this));
                });
            }, 250);
        });
        el.find('.other-recipes-slider__slides').each(function(){
            tileRecipeSlick($(this));
        });
        el.find('.other-recipes-slider__slide').on('hover', function(){
            var data = $(this).data();
            var tile = $(this).closest(".tile-article__wrapper");

            tile.find("[data-target-recipe-url]").each(function(){
                $(this).attr("href", data.recipeUrl);
            });
            tile.find("[data-target-recipe-image]").attr("src", data.recipeImg);
            tile.find("[data-target-recipe-image]").attr("alt", data.recipeTitle);
            tile.find("[data-target-recipe-image]").attr("title", data.recipeTitle);
        });
    }

    var Sage = {
      // All pages
      'common': {
        init: function() {},
        finalize: function() {

            $('.fc-recipe-carrousel').each(function(){
                initRecipeCarrousel($(this));
            });
            $('.home-container').on( 'append.infiniteScroll', function( event, response, path, items ) {
                var arr = jQuery.makeArray( items );
                var i;
                for (i = 0; i < arr.length; ++i) {
                    initRecipeCarrousel($(arr[i]).find('.fc-recipe-carrousel'));
                }
            });
        }
      },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
      fire: function(func, funcname, args) {
        var fire;
        var namespace = Sage;
        funcname = (funcname === undefined) ? 'init' : funcname;
        fire = func !== '';
        fire = fire && namespace[func];
        fire = fire && typeof namespace[func][funcname] === 'function';

        if (fire) {
          namespace[func][funcname](args);
        }
      },
      loadEvents: function() {
        // Fire common init JS
        UTIL.fire('common');

        // Fire page-specific init JS, and then finalize JS
        $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, 'finalize');
        });

        // Fire common finalize JS
        UTIL.fire('common', 'finalize');
      }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

  })(jQuery); // Fully reference jQuery after this point.
